<template>
  <div class="action-block">
    <div class="common-action dark-gray" @click="showModal">
      <i :class="block.icon"></i> {{ block.name }}
    </div>

    <b-modal id="notification-modal" v-model="isShowModal" title="Notification" size="lg">
      <TextInput v-model="block.name" label="Label" />

      <TextAreaAction v-model="block.options.content" label="Content"
                      :rows="3" :show-mergefield="true" :sms-length="true" :hide-title="true" :show-redemption="false"
                      :hide-expire-date="true" :hide-upload="true" :show-keyword="true" :show-contact-link="true" :hide-contact-dropdown="false" :hide-samcart-dropdown="false"
                      :add-optout="true" name="message" rules="required"/>
      <br />
<!--      <b-form-checkbox
          v-model="block.options.prequalify"
          size="sm"
          switch
          inline
          style="margin-top: -30px; display:block;"
      >
        Pre-qualify
        <span v-b-tooltip.hover title="If you opt-in for this feature, it will seamlessly append a configurable message to your outgoing communications: 'Please rate us by replying with a number from 1 to 5.' For ratings below 4, recipients will receive a link to your Google Business Profile, while ratings of 4 or 5 will trigger the transmission of a review link." class="copy-icon">
                  <i class="uil uil-question-circle" />
                </span>
      </b-form-checkbox>-->
      <!-- <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Method</label>
            <select v-model="block.options.method" class="form-control">
              <option value="Sms">SMS</option>
              <option value="Email">Email</option>
              <option value="EmailAndSms">Email + SMS</option>
            </select>
          </div>
        </div>
      </div> -->

      <template v-slot:modal-footer>
        <div v-if="!hasReviewLink" class="text-danger d-block">You must include the Review link in your message!</div>
        <b-button variant="primary" @click="save">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TextAreaAction from "@components/Business/Automations/TextAreaAction.vue";
export default {
  components: {
    TextAreaAction
  },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      isShowModal: false,
      hasReviewLink:true,
    }
  },

  mounted() {
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }

    this.block.options = {
      method: 'Sms',
      prequalify: false,
      ...this.block.options
    }
  },

  methods: {
    save() {
      this.hasReviewLink = this.block.options.content.includes("[review_link]");
      if(this.hasReviewLink) {
        this.isShowModal = false
      }
    },

    showModal() {
      this.isShowModal = true
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
